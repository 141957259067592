import React, { useRef } from "react";
import { JourneyContextComponent } from "./src/helpers/context";
import { Helmet } from "react-helmet";
import { Script } from "gatsby";
import posthog from 'posthog-js'

import "./src/styles/global.css";
import "./src/styles/components.css";
import "./src/styles/markdown.css";
import "@fontsource/dm-sans";
import "@fontsource/dm-sans/700.css";
import "@fontsource/dm-sans/500.css";
import "@fontsource/merriweather/300.css";
import "@fontsource/merriweather/400.css";
import "@fontsource/merriweather/700.css";
import "@fontsource/merriweather/900.css";

if (process.env.NODE_ENV === "development") {
  require("./src/styles/dev.css");
}

export const wrapRootElement = ({ element }) => {
  posthog.init('phc_cqikHGbaVk8UlXsJgS0aQes1NCp8hmnHNrMZsHj6RyD', { api_host: "https://eu.i.posthog.com" });
  return (
    <>
      <JourneyContextComponent>
        <Helmet>
          <meta
            name="google-site-verification"
            content="8ctcpBxOiSj32Kc4N2gPuz5As7GYh-6W7hNrDZhLXb4"
          />
          {/* <!-- VWO script --> */}
          <Script type="text/javascript" id="vwoCode">
            {`
            window._vwo_code=window._vwo_code || (function() {
            var account_id=628491,
            version=1.3,
            settings_tolerance=2000,
            library_tolerance=2500,
            use_existing_jquery=false,
            is_spa=1,
            hide_element='body',
            /* DO NOT EDIT BELOW THIS LINE */
            f=false,d=document,code={use_existing_jquery:function(){return use_existing_jquery},library_tolerance:function(){return library_tolerance},finish:function(){if(!f){f=true;var e=d.getElementById('_vis_opt_path_hides');if(e)e.parentNode.removeChild(e)}},finished:function(){return f},load:function(e){var t=d.createElement('script');t.fetchPriority='high';t.src=e;t.type='text/javascript';t.innerText;t.onerror=function(){_vwo_code.finish()};d.getElementsByTagName('head')[0].appendChild(t)},init:function(){window.settings_timer=setTimeout(function(){_vwo_code.finish()},settings_tolerance);var e=d.createElement('style'),t=hide_element?hide_element+'{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}':'',i=d.getElementsByTagName('head')[0];e.setAttribute('id','_vis_opt_path_hides');e.setAttribute('nonce',document.querySelector('#vwoCode').nonce);e.setAttribute('type','text/css');if(e.styleSheet)e.styleSheet.cssText=t;else e.appendChild(d.createTextNode(t));i.appendChild(e);this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+ +is_spa+'&vn='+version);return settings_timer}};window._vwo_settings_timer = code.init();return code;}());
            `}
          </Script>
          {/* <!-- Google Tag Manager --> */}
          {/* <script>
            {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=nNtt-8-McsS1mWuy6eJkfQ&gtm_preview=env-4&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-PMQ8G83');
          `}
          </script> */}

          {/* <!-- TrustBox script --> */}
          <Script
            type="text/javascript"
            src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
            async
          ></Script>

          {/* Hubspot Forms */}
          {/* <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2-legacy.js"></script> */}
          <script
            charset="utf-8"
            type="text/javascript"
            src="https://js-eu1.hsforms.net/forms/embed/v2.js"
          ></script>

          {/* Typeform Embed Library */}
          <script src="//embed.typeform.com/next/embed.js"></script>
        </Helmet>
        {element}
        {/* <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-PMQ8G83&gtm_auth=nNtt-8-McsS1mWuy6eJkfQ&gtm_preview=env-4&gtm_cookies_win=x"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          ></iframe>
        </noscript> */}
      </JourneyContextComponent>
    </>
  );
};
